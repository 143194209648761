import { getCookie } from '@heytutor/core'
import { AUTH_ENDPOINT } from 'utils/config'
import { getAuthenticationState } from '../stores'
import { executeRequest, RequestMethod } from './api'
import { showInfoMessage } from './notifications'
import { goToLogin } from './routings'

const channel = new BroadcastChannel('heytutor_channel')

channel.onmessage = (event) => {
  switch (event.data) {
    case 'logOut':
      logOut(false, false)
      break
  }
}

let logoutTask = null
let logoutWarningTask = null

export async function authorizeUser() {
  const { setUser } = getAuthenticationState()

  try {
    await executeRequest('/sanctum/csrf-cookie', { baseUrl: AUTH_ENDPOINT })

    const userData = await executeRequest('/api/user', { baseUrl: AUTH_ENDPOINT })
    const sessionData = JSON.parse(getCookie('heytutor_session_data'))
    const { sessionExpirationTimestamp } = sessionData
    const currentDate = new Date()
    const sessionExpirationDate = new Date(sessionExpirationTimestamp * 1000)
    const millisecondsToExpiration = Math.max(0, sessionExpirationDate.getTime() - currentDate.getTime())
    const millisecondsToExpirationWarning = millisecondsToExpiration - 1 * 60 * 1000

    if (logoutTask != null) {
      clearTimeout(logoutTask)
    }

    if (logoutWarningTask != null) {
      clearTimeout(logoutWarningTask)
    }

    if (millisecondsToExpiration > 0) {
      logoutTask = setTimeout(() => {
        logOut()
      }, millisecondsToExpiration)
    }

    if (millisecondsToExpirationWarning > 0) {
      logoutWarningTask = setTimeout(() => {
        showInfoMessage("You'll be logged out soon !!", 30000)
      }, millisecondsToExpirationWarning)
    }

    setUser({
      id: userData.id,
      publicId: userData.publicId,
      role: userData.roleId,
      firstName: userData.profile.firstName,
      lastName: userData.profile.lastName,
      email: userData.email,
      avatarUrl: userData.avatarUrl,
      district: { id: userData.profile.schoolId, name: userData.profile.districtName },
      school: { id: userData.profile.specificSchoolId }
    })
  } catch (e) {
    logOut()
  }
}

export async function logOut(logOutOnServer = true, logOutOnOtherWindows = true) {
  const { setUser } = getAuthenticationState()

  // Clear session data
  setUser(null)

  // Send Logout message to all other open windows
  if (logOutOnOtherWindows) {
    channel.postMessage('logOut')
  }

  // Logout from server
  if (logOutOnServer) {
    try {
      await executeRequest('/api/user/logout', {
        baseUrl: AUTH_ENDPOINT,
        method: RequestMethod.POST
      })
    } catch (e) {}
  }

  // Redirect to login
  goToLogin()
}
