import { LessonType } from 'graphql/generated'
import Yup from 'utils/Yup'
import { SelectItem } from './ui'

export const lessonTypesNames = {
  [LessonType.Standard]: LessonType.Standard,
  [LessonType.Diagnostic1]: 'Diagnostic 1',
  [LessonType.Diagnostic2]: 'Diagnostic 2',
  [LessonType.Diagnostic3]: 'Diagnostic 3',
  [LessonType.Baseline]: LessonType.Baseline,
  [LessonType.Summative]: LessonType.Summative,
  [LessonType.Practice]: LessonType.Practice,
  [LessonType.Formative]: LessonType.Formative
}

export interface WithId {
  id?: string
}

export enum SlideType {
  GoogleSlide = 'google-slide',
  Editor = 'editor'
}

export interface GoogleSlideContent {
  title: string
  editLink: string
  publishLink: string
}

export interface Slide extends WithId {
  content: GoogleSlideContent // For Google Slide is the url, if is our custom editor either the content of the slide or an S3 url
  type: SlideType
  lessonId: string
}

export enum LessonItemType {
  Quiz = 'Quiz',
  Slide = 'Slice'
}

export interface LessonItem extends WithId {
  data: Quiz | Slide
  order: number
  type: LessonItemType
}

export function getLessonTypes(allTypes: boolean = false): SelectItem[] {
  return allTypes
    ? [
        {
          name: lessonTypesNames[LessonType.Standard],
          value: LessonType.Standard
        },
        {
          name: LessonType.Practice,
          value: LessonType.Practice
        },
        {
          name: LessonType.Baseline,
          value: LessonType.Baseline
        },
        {
          name: LessonType.Formative,
          value: LessonType.Formative
        },
        {
          name: LessonType.Summative,
          value: LessonType.Summative
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic1],
          value: LessonType.Diagnostic1
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic2],
          value: LessonType.Diagnostic2
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic3],
          value: LessonType.Diagnostic3
        }
      ]
    : [
        {
          name: lessonTypesNames[LessonType.Standard],
          value: LessonType.Standard
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic1],
          value: LessonType.Diagnostic1
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic2],
          value: LessonType.Diagnostic2
        },
        {
          name: lessonTypesNames[LessonType.Diagnostic3],
          value: LessonType.Diagnostic3
        }
      ]
}

interface CurriculumCategorizedData extends WithId {
  title: string
  description?: string
  level: string
  subject: string
  metaTags: SelectItem[]
}

export interface Lesson extends CurriculumCategorizedData {
  internalTitle?: string
  items: LessonItem[]
  type: LessonType | ''
}

export interface LessonPath extends WithId {
  title: string
  description?: string
  level: string
  school: string
  semester: string
  subject?: string
  metaTags: SelectItem[]
}

export interface Quiz extends CurriculumCategorizedData {
  isGraded: boolean
  questions: Question[]
}

export interface Answer extends WithId {
  value: string
  isCorrect: boolean
  image?: string
}

export interface Question extends WithId {
  type?: any
  question: string
  explanation: string
  imageUrl?: string
  audioUrl?: string
  isMultipleChoice?: boolean
  isGraded?: boolean
  forAssessment?: boolean
  feedback?: string
  answers: Answer[]
  nextQuestion?: Question
}

const baseSchema = {
  title: Yup.string().max(255, 'Must be at most 255 characters').required(),
  description: Yup.string().max(255, 'Must be at most 255 characters').nullable(),
  metaTags: Yup.array()
  //.min(2, 'You should add at least 2 tags.')
  //.required()
}

export const quizValidationSchema = Yup.mergeSchemas(baseSchema, {})

export const newLessonValidationSchema = Yup.mergeSchemas(baseSchema, {
  level: Yup.object({
    id: Yup.number().required()
  }),
  subject: Yup.object({
    id: Yup.number().required()
  }),
  items: Yup.array(),
  type: Yup.string().oneOf([
    LessonType.Standard,
    LessonType.Diagnostic1,
    LessonType.Diagnostic2,
    LessonType.Diagnostic3
  ])
})

export const lessonValidationSchema = Yup.mergeSchemas(baseSchema, {
  level: Yup.object({
    id: Yup.number().required()
  }),
  subject: Yup.object({
    id: Yup.number().required()
  }),
  items: Yup.array(),
  type: Yup.string()
    .oneOf([
      LessonType.Standard,
      LessonType.Practice,
      LessonType.Baseline,
      LessonType.Formative,
      LessonType.Summative,
      LessonType.Diagnostic1,
      LessonType.Diagnostic2,
      LessonType.Diagnostic3
    ])
    .required()
})

export const metatagValidationSchema = Yup.object({
  cc_standards: Yup.string().required().min(3, 'The CC Standards should contain at least 3 characters')
})

export const lessonPathValidationSchema = Yup.mergeSchemas(baseSchema, {
  level: Yup.object({
    id: Yup.number().required()
  }),
  school: Yup.object({
    id: Yup.number()
  }),
  subject: Yup.object({
    id: Yup.number()
  })
})

export function getNewAnswer(value: string = '', isAnswer: boolean = false): Answer {
  return {
    value: value,
    isCorrect: isAnswer
  }
}

export function getQuestion(question: string = '', isAnswer: boolean = true): Question {
  return {
    question: question,
    explanation: '',
    isGraded: false,
    forAssessment: false,
    feedback: '',
    answers: [getNewAnswer('', isAnswer), getNewAnswer('', !isAnswer)]
  }
}

export function getQuiz(): Quiz {
  return {
    title: '',
    description: '',
    level: '',
    subject: '',
    isGraded: false,
    metaTags: [],
    questions: [getQuestion()]
  }
}

export function getLesson(): Lesson {
  return {
    title: '',
    internalTitle: '',
    description: '',
    level: '',
    subject: '',
    metaTags: [],
    items: [],
    type: ''
  }
}

export function getLessonPath(): LessonPath {
  return {
    title: '',
    description: '',
    level: '',
    school: '',
    subject: '',
    semester: '',
    metaTags: []
  }
}
