import { DISTRICT_SCHOOL_DASHBOARD_URL } from './config'

export interface QueryParam {
  name: string
  value: string
}

function withQueryParams(url: string, query: QueryParam[]) {
  const queryString = query.map((x) => `${x.name}=${x.value}`).join('&')

  return query && query.length > 0 ? `${url}?${queryString}` : url
}

const schoolsFrontendUrl = DISTRICT_SCHOOL_DASHBOARD_URL
const routes = {
  quizzes: '/quizzes',
  editQuiz: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/quizzes/${id}`, query)
  },
  editQuestion: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/questions/${id}`, query)
  },
  editFlashCard: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/flash-cards/${id}`, query)
  },
  editFillingBlanks: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/filling-blanks/${id}`, query)
  },
  editMatchingPairs: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/matching-pairs/${id}`, query)
  },
  editMemoTest: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/memo-test/${id}`, query)
  },
  lessons: '/lessons',
  editLesson: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/lessons/${id}`, query)
  },
  previewLesson: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/lessons/preview/${id}`, query)
  },
  takeLesson: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/lessons/take/${id}`, query)
  },
  viewStatsLesson: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/lessons/stats/${id}`, query)
  },
  lessonPaths: '/lesson-paths',
  editLessonPath: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/lesson-paths/${id}`, query)
  },
  metatags: '/metatags',
  editMetaTags: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/metatags/${id}`, query)
  },
  districts: '/districts',
  editDistrict: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/districts/${id}`, query)
  },
  tickets: '/tickets',
  sessionJoin: function (id: string, query: QueryParam[] = []) {
    return withQueryParams(`/session-join/${id}`, query)
  },
  permissionDenied: '/auth/permission-denied',

  schoolsFrontend: {
    index(query: QueryParam[] = []) {
      return withQueryParams(schoolsFrontendUrl, query)
    },
    classDetails(query: QueryParam[] = []) {
      return withQueryParams(`${schoolsFrontendUrl}/class-lesson`, query)
    }
  }
}

export default routes
