import '@edtr-io/mathquill/build/mathquill.css'
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import FunctionIcon from 'components/design-system/bits/function-icon'
import $ from 'jquery'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { renderLatexFormula } from 'utils/latex'
import { Control } from './Control'

if (typeof window !== 'undefined') {
  //@ts-ignore
  window.jQuery = window.$ = $
  require('@edtr-io/mathquill/build/mathquill.js')
}

interface MathFieldProps {
  latex?: string
  onChange?: (latex: string) => void
}

// eslint-disable-next-line react/display-name
const MathField = forwardRef(({ latex = '', onChange = undefined }: MathFieldProps, ref) => {
  const [currentLatex, setCurrentLatex] = useState<string>(latex)
  const mathField = useRef(null)
  const wrapperElement = useRef(null)

  const insertOperator = (operator: string) => {
    if (/^\\[A-Za-z]+$/.test(operator)) {
      mathField.current.cmd(operator)
    } else {
      mathField.current.write(operator)
    }

    mathField.current.focus()
  }

  useImperativeHandle(ref, () => ({
    insertOperator
  }))

  useEffect(() => {
    if (wrapperElement.current) {
      //@ts-ignore
      mathField.current = window.MathQuill.getInterface(2).MathField(wrapperElement.current, {
        restrictMismatchedBrackets: true,
        handlers: {
          edit(mathField) {
            setCurrentLatex(mathField.latex())
          }
        }
      })
      mathField.current.latex(currentLatex)
      mathField.current.focus()
    }
  }, [wrapperElement.current])

  useEffect(() => {
    if (latex != currentLatex) {
      setCurrentLatex(latex)

      if (mathField.current) {
        mathField.current.latex(latex)
      }
    }
  }, [latex])

  useEffect(() => {
    if (onChange && currentLatex != latex) {
      onChange(currentLatex)
    }
  }, [currentLatex])

  return <Box as="span" width="100%" ref={wrapperElement} />
})

interface MathOperatorButtonProps {
  latex: string
  onClick?: () => void
}

function MathOperatorButton({ latex, onClick = undefined }: MathOperatorButtonProps) {
  const latexContainerRef = useRef(null)

  useEffect(() => {
    if (latexContainerRef?.current) {
      renderLatexFormula(latex, latexContainerRef?.current)
    }
  }, [latexContainerRef?.current])

  return (
    <IconButton
      aria-label=""
      fontSize="13px"
      fontWeight="800"
      size="md"
      tabIndex={-1}
      margin="0 !important"
      ref={latexContainerRef}
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      onMouseDown={(e) => {
        e.preventDefault()
      }}
    />
  )
}

type MathOperator =
  | {
      latex: string
      displayLatex: string
    }
  | string

interface MathModalProps {
  quill: any
  isOpen?: boolean
  onClose?: () => void
  operators?: Array<MathOperator>
}

function MathModal({
  quill,
  isOpen,
  onClose,
  operators = [
    '\\text{+}',
    '\\text{-}',
    {
      displayLatex: '*',
      latex: '\\cdot'
    },
    {
      displayLatex: '/',
      latex: '\\frac'
    },
    '×',
    '÷',
    '=',
    '>',
    '<',
    '\\geq',
    '\\leq',
    '\\neq',
    '\\pm',
    '(',
    ')',
    {
      displayLatex: '\\overline{x}',
      latex: '\\overline'
    },
    {
      displayLatex: 'x^2',
      latex: '^2'
    },
    {
      displayLatex: 'x^n',
      latex: '^{}'
    },
    {
      displayLatex: 'x_n',
      latex: '_{}'
    },
    {
      displayLatex: '\\sqrt{x}',
      latex: '\\sqrt'
    },
    {
      displayLatex: '\\sqrt[n]{x}',
      latex: '\\nthroot'
    },
    {
      displayLatex: '\\sum^{s}_{x}{d}',
      latex: '\\sum'
    },
    {
      displayLatex: '\\prod^{s}_{x}{d}',
      latex: '\\prod'
    },
    {
      displayLatex: '\\int^{s}_{x}{d}',
      latex: '\\int'
    },
    '\\sin',
    '\\cos',
    '\\tan',
    '\\cot',
    '\\sec',
    '\\csc',
    '\\lim',
    '\\rightarrow',
    '\\leftarrow',
    '\\leftrightarrow',
    '\\emptyset',
    '\\in',
    '\\notin',
    '\\subset',
    '\\supset',
    '\\cap',
    '\\cup',
    '\\infty',
    '\\alpha',
    '\\beta',
    '\\gamma',
    '\\delta',
    '\\pi'
  ]
}: MathModalProps) {
  const [latex, setLatex] = useState<string>('')
  const mathFieldRef = useRef(null)

  const onSubmitClicked = () => {
    const range = quill.getSelection(true)

    quill.deleteText(range.index, range.length)
    quill.insertEmbed(range.index, 'math-formula', latex)
    quill.insertText(range.index + range.length + 1, ' ')
    quill.setSelection(range.index + range.length + 1)
    onClose()
  }

  const insertOperator = (latex: string) => {
    mathFieldRef.current.insertOperator(latex)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent rounded={'none'}>
        <ModalHeader>Add Formula</ModalHeader>
        <ModalBody>
          <HStack gap="5px" padding="0 0 20px 0" flexWrap="wrap">
            {operators.map((operator, index) => (
              <MathOperatorButton
                key={index}
                latex={typeof operator === 'string' ? operator : operator.displayLatex}
                onClick={() => insertOperator(typeof operator === 'string' ? operator : operator.latex)}
              />
            ))}
          </HStack>
          <MathField ref={mathFieldRef} latex={latex} onChange={setLatex} />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={2}>
            <Button onClick={onClose}>Cancel</Button>
            <Button colorScheme="blue" onClick={onSubmitClicked}>
              Save
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const MathFormulaSelectorButton = ({ quill }) => {
  const [mathModalOpen, setMathModalOpen] = useState<boolean>(false)

  return (
    <button title="Math formula" onClick={() => setMathModalOpen(true)}>
      {mathModalOpen && <MathModal quill={quill} isOpen={mathModalOpen} onClose={() => setMathModalOpen(false)} />}
      <FunctionIcon />
    </button>
  )
}

export const MathFormula: Control = {
  id: 'math-formula-selector',
  formats: ['math-formula'],
  renderer: (quill) => <MathFormulaSelectorButton quill={quill} />
}
