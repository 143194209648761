const tutorsRoutes = [
  '/404',
  '/lessons/preview/[id]',
  '/lessons/present',
  '/auth/callback',
  '/auth/permission-denied',
  '/session/[sessionPublicId]'
]
const studentsRoutes = ['/404', '/auth/callback', '/auth/permission-denied', '/session/[sessionPublicId]']
const homeStudentsRoutes = [
  '/404',
  '/auth/callback',
  '/auth/permission-denied',
  '/session/[sessionPublicId]',
  '/session-results/[sessionPublicId]'
]
const homeGuardianRoutes = ['/404', '/guardian/[studentId]']
// shared with Admin & ContentAdmin
const adminsRoutes = [
  '/Home',
  '/lessons',
  '/lessons/[id]',
  '/lessons/preview/[id]',
  '/lessons/take/[id]',
  '/lessons/stats/[id]',
  '/questions/[id]',
  '/flash-cards/[id]',
  '/filling-blanks/[id]',
  '/matching-pairs/[id]',
  '/lesson-paths',
  '/lesson-paths/[id]',
  '/metatags',
  '/metatags/[id]',
  '/districts',
  '/districts/[id]',
  '/tickets',
  '/settings',
  '/404',
  '/auth/callback',
  '/auth/permission-denied',
  '/session/[sessionPublicId]'
]

export const allowedRoutesByRole = {
  student: studentsRoutes,
  homeStudents: homeStudentsRoutes,
  homeGuardians: homeGuardianRoutes,
  tutor: tutorsRoutes,
  admin: adminsRoutes
}
