import { ChakraProvider, extendTheme, Switch as NBSwitch } from '@chakra-ui/react'
import { SwitchProps } from '@heytutor/common'
import { useField, useFormikContext } from 'formik'
import FieldWrapper from './field-wrapper'

type Props = SwitchProps & any

export const customTheme = extendTheme({
  colors: {
    HeyTutorSwitchGreen: {
      500: '#49C549'
    }
  }
})

const Switch = ({ label, name, helper, onChange, ...rest }: Props) => {
  const [field] = useField(name)
  const { setFieldValue }: any = useFormikContext()

  return (
    <FieldWrapper label={label} name={name} helper={helper}>
      <ChakraProvider theme={customTheme}>
        <NBSwitch
          {...rest}
          colorScheme="HeyTutorSwitchGreen"
          isChecked={field.value}
          onChange={() => {
            const newValue = !field.value

            setFieldValue(name, newValue)

            if (onChange) {
              onChange(newValue)
            }
          }}
        />
      </ChakraProvider>
    </FieldWrapper>
  )
}

export default Switch
